import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import styles from "./BarChart.module.css"; // Import the CSS module

const DailyCharts = ({ transactionGraph, colors, type }) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    setSelectedIndex(-1);
  }, [transactionGraph]);

  const processData = (graphData) => {
    if (!graphData) return null;
    return graphData.map((dayData) => {
      const categories = [
        "0-3",
        "3-6",
        "6-9",
        "9-12",
        "12-15",
        "15-18",
        "18-21",
        "21-24",
      ];

      const seriesData = [
        dayData.hour0To3Transactions.reduce((acc, t) => acc + t.sold, 0),
        dayData.hour3To6Transactions.reduce((acc, t) => acc + t.sold, 0),
        dayData.hour6To9Transactions.reduce((acc, t) => acc + t.sold, 0),
        dayData.hour9To12Transactions.reduce((acc, t) => acc + t.sold, 0),
        dayData.hour12To15Transactions.reduce((acc, t) => acc + t.sold, 0),
        dayData.hour15To18Transactions.reduce((acc, t) => acc + t.sold, 0),
        dayData.hour18To21Transactions.reduce((acc, t) => acc + t.sold, 0),
        dayData.hour21To24Transactions.reduce((acc, t) => acc + t.sold, 0),
      ];

      return {
        date: new Date(dayData.date).toLocaleDateString(),
        categories,
        series: [
          {
            name: `Transactions on ${new Date(dayData.date).toLocaleDateString()}`,
            data: seriesData,
          },
        ],
      };
    });
  };

  const chartData = processData(transactionGraph);

  let globalMax = null;
  if (chartData)
    globalMax = chartData.reduce(
      (max, data) => {
        const localMax = Math.max(...data.series[0].data);
        return localMax > max ? localMax : max;
      },
      0
    );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Des"
    ];
    const month = monthNames[date.getUTCMonth()]; // Use getUTCMonth() for UTC month
    const day = date.getUTCDate(); // Use getUTCDate() for UTC day
    return { month, day };
  };


  return (
    <div className={styles.chartItemContainer}>
      {chartData &&
        chartData.map((data, index) => (
          <div
            key={index}
            className={`${styles.chartItemWrapper} ${selectedIndex !== -1 && selectedIndex !== index
              ? styles.chartItemWrapperActive
              : styles.chartItemWrapperInactive
              }`}
          >
            <div className={styles.dateSelectorWrapper}>
              {chartData.map((item, indexx) => {
                const { month, day } = formatDate(item.date);
                return (
                  <div
                    key={indexx}
                    className={`${styles.dateSelector} ${index === indexx ? styles.dateSelectorActive : styles.dateSelectorInactive
                      }`}
                      style={{border: (index==0||index==1) && selectedIndex != index && selectedIndex != indexx || selectedIndex ==-1 && index == 0 || selectedIndex == index && index == indexx ? 
                        '1px solid rgb(179, 177, 177)' : 'none' }}
                    onClick={() =>
                      type == 'yesterday' && selectedIndex == -1 || type != 'yesterday' && selectedIndex !== index ? setSelectedIndex(index) : setSelectedIndex(-1)
                    }
                    // style={{ backgroundColor: index === indexx ? colors[index % colors.length] : 'transparent' }}
                  >
                    <div 
                    style={{ color: index === indexx ? colors[index % colors.length] : 'transparent' }}>
                      {indexx !== chartData.length - 1 ? (
                        <>
                          {day}{" "}
                          {(indexx === 0 || (formatDate(chartData[indexx - 1].date).month !== month && type != 'weekly')) && month}
                        </>
                      ) : (
                        'kemarin'
                      )}

                    </div>
                  </div>
                );
              })}
            </div>
            <div className={styles.chartWrapper}>
              <Chart
                options={{
                  tooltip: { enabled: false },
                  chart: {
                    id: `chart-${index}`,
                    type: "area",
                    zoom: {
                      enabled: false,
                    },
                    toolbar: {
                      show: false,
                    },
                  },
                  xaxis: {
                    categories: data.categories,
                    labels: {
                      style: {
                        colors: index === 0 || index == selectedIndex || selectedIndex == 0 && index == 1 ? "#000" : "transparent",
                      },
                    },
                  },
                  yaxis: {
                    max: globalMax,
                    labels: {
                      style: {
                        colors: "transparent",
                      },
                    },
                  },
                  grid: {
                    show: false,
                  },
                  fill: {
                    opacity: 0.5,
                  },
                  colors: [colors[index % colors.length]],
                }}
                series={data.series}
                type="area"
                height={200}
                width="100%"
              />
            </div>
          </div>

        ))}
    </div>
  );
};

export default DailyCharts;
