import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import styles from "./BarChart.module.css"; // Import the CSS module

const PeriodCharts = ({ type, aggregatedCurrentReports, aggregatedPreviousReports, colors }) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    setSelectedIndex(-1);
  }, [aggregatedCurrentReports, aggregatedPreviousReports]);

  const monthly = ["1 - 7", "8 - 14", "15 - 21", "22 - 28", "29 - 31"];
  const yearly = ["Kuartal 1", "Kuartal 2", "Kuartal 3", "Kuartal 4"];
  const cat = type == 'monthly' ? monthly : yearly;

  // Map the data for the current reports
  let currentIncomeData, currentOutcomeData, currentTransactionData, previousIncomeData, previousOutcomeData, previousTransactionData = null;
  if (aggregatedCurrentReports) {
    currentIncomeData = aggregatedCurrentReports.map((report) => report.income);
    currentOutcomeData = aggregatedCurrentReports.map((report) => report.outcome);
    currentTransactionData = aggregatedCurrentReports.map((report) => report.transactions);
  }
  if (aggregatedPreviousReports) {
    // Map the data for the previous reports
    previousIncomeData = aggregatedPreviousReports.map((report) => report.income);
    previousOutcomeData = aggregatedPreviousReports.map((report) => report.outcome);
    previousTransactionData = aggregatedPreviousReports.map((report) => report.transactions);
  }
  let globalMax = null;
  if (aggregatedCurrentReports || aggregatedPreviousReports)
    // Find the global maximum for the y-axis
    globalMax = Math.max(
      // ...currentIncomeData,
      // ...currentOutcomeData,
      ...currentTransactionData,
      // ...previousIncomeData,
      // ...previousOutcomeData,
      ...previousTransactionData
    );

  return (
    <div className={styles.chartItemContainer}>

      {aggregatedPreviousReports && (
        <div className={`${styles.chartItemWrapper} ${selectedIndex !== -1 && selectedIndex !== 0
          ? styles.chartItemWrapperActive
          : styles.chartItemWrapperInactive
          }`}>

          <div className={styles.dateSelectorWrapper}>
            <div className={styles.dateSelector}
              onClick={() =>
                selectedIndex === -1 ? setSelectedIndex(0) : setSelectedIndex(-1)
              }
              style={{border: '1px solid rgb(179, 177, 177)', color: colors[0]}}
             >
              <div>{type == 'monthly' ? 'bulan lalu' : 'tahun lalu'}</div>
            </div>
            <div
              className={`${styles.dateSelector} ${styles.dateSelectorInactive
                }`}
                onClick={() =>
                  selectedIndex === 0 ? setSelectedIndex(-1) : setSelectedIndex(1)
                }>
              <div>{type == 'monthly' ? 'bulan ini' : 'tahun ini'}</div>
            </div>
          </div>
          <div className={styles.chartWrapper}>
            <Chart
              options={{
                tooltip: { enabled: false },
                chart: { type: "area", zoom: { enabled: false }, toolbar: { show: false } },
                xaxis: { 
                  categories: cat,
                  axisBorder: {
                    show: false, // Removes the x-axis line
                  },
                  axisTicks: {
                    show: false, // Removes the ticks on the x-axis
                  },
                 },
                yaxis: { max: globalMax, min: 0, labels: { style: { colors: "transparent" } } },
                grid: { show: false },
                fill: { opacity: 0.5 },
                colors: [colors[0]],
              }}
              series={[
                // { name: "Pemasukan", data: previousIncomeData },
                // { name: "Pengaluaran", data: previousOutcomeData },
                { name: "Total transaksi", data: previousTransactionData },
              ]}
              type="area"
              height={200}
              width="100%"
            />
          </div>
        </div>
      )}
      {aggregatedCurrentReports && (
        <div className={`${styles.chartItemWrapper} ${selectedIndex !== -1 && selectedIndex !== 1
          ? styles.chartItemWrapperActive
          : styles.chartItemWrapperInactive
          }`}>
          <div className={styles.dateSelectorWrapper}>
            <div
              className={`${styles.dateSelector} ${styles.dateSelectorInactive
                }`}
                onClick={() =>
                  selectedIndex === 1 ? setSelectedIndex(-1) : setSelectedIndex(0)
                }>
              <div>{type == 'monthly' ? 'bulan lalu' : 'tahun lalu'}</div>
            </div>
            <div className={styles.dateSelector}
              onClick={() =>
                selectedIndex === -1 ? setSelectedIndex(1) : setSelectedIndex(-1)
              }
              
              style={{border: '1px solid rgb(179, 177, 177)', color: colors[1]}}>
              <div>{type == 'monthly' ? 'bulan ini' : 'tahun ini'}</div>
            </div>
          </div>
          <div className={styles.chartWrapper}>
            <Chart
              options={{
                tooltip: { enabled: false },
                chart: { type: "area", zoom: { enabled: false }, toolbar: { show: false } },
                xaxis: {
                  categories: cat, 
                  axisBorder: {
                    show: false, // Removes the x-axis line
                  },
                  axisTicks: {
                    show: false, // Removes the ticks on the x-axis
                  }, },
                yaxis: { max: globalMax, min: 0, labels: { style: { colors: "transparent" } } },
                grid: { show: false },
                fill: { opacity: 0.5 },
                colors: [colors[1]],
              }}
              series={[
                // { name: "Pemasukan", data: currentIncomeData },
                // { name: "Pengeluaran", data: currentOutcomeData },
                { name: "Total transaksi", data: currentTransactionData },
              ]}
              type="area"
              height={200}
              width="100%"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PeriodCharts;
