import React, { useState } from "react";
import styles from "./Carousel.module.css"; // Import CSS module

const Carousel = ({ selectedIndex, items, onSelect }) => {

  const moveToNext = () => {
    console.log('aa')
    if (selectedIndex < items.length - 1) {
        console.log('bb')
      onSelect(selectedIndex + 1); // Send the next index to the parent
    }
  };

  const moveToPrev = () => {
    if (selectedIndex > -1) {
      onSelect(selectedIndex - 1); // Send the previous index to the parent
    }
  };

  return (
    <div className={styles.carouselContainer}>
      <div className={styles.carousel}>
        {/* Previous Item */}
        <div
          className={`${styles.carouselItem} ${styles.prev}`}
          onClick={moveToPrev}
          style={{ visibility: selectedIndex === -1 && items.length > 0 ? "hidden" : "visible" , backgroundColor: items.length < 1 ? '#919191':'#007bff'}}
        >
          {selectedIndex === -1 ?  (items.length > 0 ? "+" : "") : items[selectedIndex - 1]?.name || "+"}
        </div>

        {/* Current Item */}
        <div className={`${styles.carouselItem} ${styles.center}`}>
          {selectedIndex >= 0 ? items[selectedIndex]?.name : "+"}
        </div>

        {/* Next Item */}
        <div
          className={`${styles.carouselItem} ${styles.next}`}
          onClick={moveToNext}
          style={{
            visibility: selectedIndex === items.length -1 && items.length > 0 ? "hidden" : "visible", backgroundColor: items.length < 1 ? '#919191':'#007bff'
          }}
        >
          {selectedIndex < items.length - 1 && items[selectedIndex + 1]?.name}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
