import React, { useState, useEffect } from 'react';
import styles from './Join.module.css'; // Import the module.css file
import API_BASE_URL from '../config.js';
import Coupon from '../components/Coupon';

function getAuthToken() {
  return localStorage.getItem('auth');
}

const LinktreePage = ({ data, setModal }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const [isUsingCoupon, setIsUsingCoupon] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [couponStatus, setCouponStatus] = useState(0);
  const [couponDetails, setCouponDetails] = useState(null);

  // Detect query params on component mount
  useEffect(() => {
    if(couponCode != '') return;
    const code = queryParams.get('couponCode');
    console.log(code)
    if (code) {
      setCouponCode(code);
      setIsUsingCoupon(true);  // Automatically switch to the coupon input state
      handleCheckCoupon(code); // Automatically check the coupon code
    }
  }, [queryParams]);

  // Handle manual coupon code check
  const handleCheckCoupon = async (code = couponCode) => {
    try {
      const response = await fetch(`${API_BASE_URL}/coupon/check/${code}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAuthToken()}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setCouponStatus(200);
        setCouponDetails(data.coupon);
      } else {
        setCouponStatus(404);
        setCouponDetails(null);
      }
    } catch (error) {
      setCouponStatus(404);
      setCouponDetails(null);
    }
  };

  // Listen for query parameter changes (using the `location` object)
  useEffect(() => {
    const handlePopState = () => {
      const newQueryParams = new URLSearchParams(window.location.search);
      const newCouponCode = newQueryParams.get('couponCode');
      if (!newCouponCode) {
        setIsUsingCoupon(false);
        setCouponCode('');
        setCouponDetails(null);
        setCouponStatus(0);
      }
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <div className={styles.linktreePage}>
      {!isUsingCoupon ? (
        <div className={styles.dashboardContainer}>
          <div className={styles.mainHeading}>Nikmati Kemudahan Mengelola Kafe</div>
          <div className={styles.subHeading}>
            Daftarkan kedaimu sekarang dan mulai gunakan semua fitur unggulan kami.
          </div>
          <form className={styles.linktreeForm}>
            <label htmlFor="username" className={styles.usernameLabel}>
              --------------------------------------------
            </label>
            <input
              id="username"
              placeholder="nomor whatsapp atau email"
              maxLength="30"
              className={styles.usernameInput}
            />
            <button type="submit" className={styles.claimButton}>
              <span>➜</span>
            </button>
          </form>
          <div className={styles.footer}>
            <div className={styles.footerLinks}>
              <a
                href="https://linktr.ee/discover/trending"
                target="_blank"
                rel="noreferrer"
                className={styles.footerLink}
              >
                Pelajari lebih lanjut
              </a>
              <a
                onClick={() => setIsUsingCoupon(true)}
                className={styles.footerLink}
              >
                Gunakan kupon
              </a>
            </div>
            <div className={styles.footerImage}>
              <img src="./laporan.png" alt="Linktree visual" />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.dashboardContainer}>
          <div className={styles.mainHeading}>Daftar Menggunakan Kupon</div>
          <div className={styles.subHeading}>
            Kupon tidak hanya dapat digunakan untuk pembuatan akun penyewa, tetapi juga dapat digunakan untuk memperpanjang masa berlangganan.
          </div>
          {couponStatus === 0 ? (
            <form className={styles.linktreeForm} onSubmit={(e) => e.preventDefault()}>
              <label htmlFor="coupon" className={styles.usernameLabel}>
                --------------------------------------------
              </label>
              <input
                id="coupon"
                placeholder="kode kupon"
                maxLength="30"
                className={styles.usernameInput}
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <button onClick={() => handleCheckCoupon()} className={styles.claimButton}>
                <span>Cek</span>
              </button>
            </form>
          ) : (
            <>
              <Coupon
                code={couponDetails?.code || null}
                value={couponDetails?.discountValue}
                period={couponDetails?.discountPeriods}
                expiration={couponDetails?.expirationDate}
              />
              {couponStatus == 200 &&
              <form className={styles.linktreeForm}>
                <label htmlFor="username" className={styles.usernameLabel}>
                  --------------------------------------------
                </label>
                <button
                  type="submit"
                  className={styles.claimButton}
                  style={{ width: '266px' }}
                  onClick={() => setModal('create_user', { codeStatus: 200, couponCode })}
                >
                  <span>Buat akun dengan kupon ini</span>
                </button>
              </form>
}
            </>
          )}
          <div className={styles.footer}>
            <div className={styles.footerLinks}>
              <a
                href="https://linktr.ee/discover/trending"
                target="_blank"
                rel="noreferrer"
                className={styles.footerLink}
              >
                Pelajari lebih lanjut
              </a>
              <a
                onClick={() => {
                  // Get the current URL query parameters
                  const url = new URL(window.location.href);

                  // Remove the couponCode query parameter
                  url.searchParams.delete('couponCode');
                  url.searchParams.delete('codeStatus');

                  // Update the browser's URL, but keep 'modal=join' intact
                  window.history.pushState({}, '', url.toString());

                  // Reset the states and force the component to reset
                  setIsUsingCoupon(couponStatus == 0 ? false : true);
                  setCouponCode('');
                  setCouponDetails(null);
                  setCouponStatus(0);
                }}
                className={styles.footerLink}
              >
                Kembali
              </a>
            </div>
            <div className={styles.footerImage}>
              <img src="./laporan.png" alt="Linktree visual" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LinktreePage;
