import React, { useState, useEffect } from 'react';
import styles from './LinktreePage.module.css'; // Import the module.css file
import API_BASE_URL from '../config.js';

function getAuthToken() {
  return localStorage.getItem('auth');
}

const LinktreePage = ({ setModal }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const [isUsingCoupon, setIsUsingCoupon] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [couponStatus, setCouponStatus] = useState('');
  const [couponDetails, setCouponDetails] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [wasInputtingPassword, setWasInputtingPassword] = useState(false);
  const [inputtingPassword, setInputtingPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');

  // Detect query params on component mount
  useEffect(() => {
    const code = queryParams.get('couponCode');
    console.log(code)
    if (code) {
      setCouponStatus(200);
      setCouponCode(code);
      setIsUsingCoupon(true);  // Automatically switch to the coupon input state
    }
  }, [queryParams]);

  const handleCheckCoupon = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_BASE_URL}/coupon/check/${couponCode}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAuthToken()}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setCouponStatus('Coupon is valid');
        setCouponDetails(data.coupon);
      } else {
        setCouponStatus('Coupon not found or expired');
        setCouponDetails(null);
      }
    } catch (error) {
      setCouponStatus('Error checking coupon.');
      setCouponDetails(null);
    }
  };

  const handleCreateUserWithCoupon = async (e) => {
    e.preventDefault();

    if (password !== retypePassword) {
      setCouponStatus('Passwords do not match');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/coupon/create-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAuthToken()}`,
        },
        body: JSON.stringify({
          username,
          email,
          password,
          couponCode,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setCouponStatus('User created successfully with coupon');
        setCouponDetails(null);

        localStorage.setItem('auth', data.token);

        // Clean the URL by removing query parameters and hash
        const cleanUrl = window.location.origin + window.location.pathname;

        // Replace the current URL with the cleaned one
        window.history.replaceState(null, '', cleanUrl);

        // Reload the page with the cleaned URL (no query params or hash)
        window.location.reload();
      } else {
        const errorData = await response.json();
        setCouponStatus(errorData.message || 'Error creating user');
        setModal('join', { couponCode })
      }
    } catch (error) {
      setCouponStatus('Error creating user.');
      setModal('join', { couponCode })
    }
  };

  return (
    <div className={styles.linktreePage}>
      <div className={styles.dashboardContainer}>
        <div className={styles.mainHeading}>Gunakan Kupon</div>

        <div className={styles.LoginForm}>
          <div className={`${styles.FormUsername} ${inputtingPassword ? styles.animateForm : wasInputtingPassword ? styles.reverseForm : ''}`}>
            <label htmlFor="username" className={styles.usernameLabel}>---- masuk -------------------------------</label>
            <input
              type="text"
              placeholder="Username"
              maxLength="30"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className={!error ? styles.usernameInput : styles.usernameInputError}
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={!error ? styles.usernameInput : styles.usernameInputError}
            />
            <button onClick={() => { setInputtingPassword(true); setWasInputtingPassword(true) }} className={styles.claimButton}>
              <span>➜</span>
            </button>
          </div>

          <div className={`${styles.FormPassword} ${inputtingPassword ? styles.animateForm : wasInputtingPassword ? styles.reverseForm : ''}`}>
            <span>
              <label onClick={() => setInputtingPassword(false)} htmlFor="password" className={styles.usernameLabel}> &lt;--- &lt;-- kembali </label>
              <label htmlFor="password" className={styles.usernameLabel}> &nbsp; ----------------- &nbsp; </label>

            </span>

            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              maxLength="30"
              className={!error ? styles.usernameInput : styles.usernameInputError}
            />
            <input
              type="password"
              placeholder="Re-type Password"
              value={retypePassword}
              onChange={(e) => setRetypePassword(e.target.value)}
              maxLength="30"
              className={!error ? styles.usernameInput : styles.usernameInputError}
            />
            <button
              onClick={handleCreateUserWithCoupon}
              className={`${styles.claimButton} ${loading ? styles.loading : ''}`}
              disabled={loading}
            >
              <span>{loading ? 'Loading...' : 'Masuk'}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinktreePage;
