import React, { useState } from "react";

import API_BASE_URL from "../config.js";

function getAuthToken() {
    return localStorage.getItem("auth");
  }
const CreateCouponPage = () => {
  const [discountType, setDiscountType] = useState("percentage");
  const [discountValue, setDiscountValue] = useState("");
  const [discountPeriods, setDiscountPeriods] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_BASE_URL}/coupon/create`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAuthToken()}`,
          },
        body: JSON.stringify({
          discountType,
          discountValue,
          discountPeriods,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setMessage(`Coupon created successfully: ${data.coupon.code}`);
      } else {
        setMessage("Failed to create coupon.");
      }
    } catch (error) {
      setMessage("Error creating coupon.");
    }
  };

  return (
    <div>
      <h1>Create Coupon</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Discount Type:</label>
          <select
            value={discountType}
            onChange={(e) => setDiscountType(e.target.value)}
          >
            <option value="percentage">Percentage</option>
            <option value="fixed">Fixed</option>
          </select>
        </div>
        <div>
          <label>Discount Value:</label>
          <input
            type="number"
            value={discountValue}
            onChange={(e) => setDiscountValue(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Discount Periods (in weeks):</label>
          <input
            type="number"
            value={discountPeriods}
            onChange={(e) => setDiscountPeriods(e.target.value)}
            required
          />
        </div>
        <button type="submit">Create Coupon</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default CreateCouponPage;
