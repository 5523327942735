import React, { useState } from 'react';
import { createClerks } from '../helpers/userHelpers'; // Adjust the import path as needed
import { useLocation } from "react-router-dom";

const CreateClerk = ({ shopId }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const location = useLocation();
  
  const queryParams = new URLSearchParams(location.search);
  const cafeIdParam = queryParams.get("cafeId");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage('');

    // Basic validation
    if (!username || !password) {
      setMessage('Username and password are required');
      setLoading(false);
      return;
    }

    try {
      const create = await createClerks(shopId || cafeIdParam, username, password);

      if (create) setMessage('Clerk created successfully');
      else setMessage('Failed to create clerk');
    } catch (error) {
      setMessage('Error creating clerk');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Tambah Kasir</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={styles.input}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={styles.input}
        />
        <button type="submit" style={styles.button} disabled={loading}>
          {loading ? 'Creating...' : 'Create Clerk'}
        </button>
        {message && (
          <p style={{ ...styles.message, color: message.includes('success') ? 'green' : 'red' }}>
            {message}
          </p>
        )}
      </form>
    </div>
  );
};

// Basic styling to make it mobile-friendly with a white background
const styles = {
  container: {
    backgroundColor: '#fff',
    width: '100%',
    maxWidth: '350px',
    margin: '0 auto',
    padding: '20px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    boxSizing: 'border-box',
  },
  header: {
    textAlign: 'center',
    marginBottom: '20px',
    fontSize: '20px',
    color: '#333',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  input: {
    padding: '12px',
    fontSize: '16px',
    borderRadius: '8px',
    border: '1px solid #ccc',
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: '#f9f9f9',
  },
  button: {
    padding: '12px',
    fontSize: '16px',
    borderRadius: '8px',
    border: 'none',
    backgroundColor: '#28a745',
    color: 'white',
    cursor: 'pointer',
    width: '100%',
  },
  message: {
    textAlign: 'center',
    marginTop: '10px',
  },
};

export default CreateClerk;
