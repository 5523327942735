import React from 'react';
import './Coupon.css'; // Import a CSS file for styling

const Coupon = ({ code, value, period, type, expiration }) => {
    // Format the value based on type
    const formattedValue = type === 'fixed' ? `Rp ${value}` : value != 0 ? `${value}%` : 'kupon berlangganan';

    return (
        <div className='coupon'>
            <div className='coupon-left'>
                <div className='coupon-code'>{code == null ? '404' : code}</div>
                <div className='dotted-line'></div>
            </div>
            <div className='coupon-right'>
                <h2 className='coupon-value'>{code == null ? 'Kupon tidak ditemukan' : formattedValue}</h2>
                {type && <span className='coupon-type'>{type}</span>} {/* Display type if provided */}
                <p className='coupon-period'>
                    {code == null ? '-' : value == 0 ? `Masa berlangganan ${period} minggu` : `Masa kupon ${period} minggu`} {/* Fixed string concatenation */}
                </p>
                <p className='coupon-expiration'>
                    {expiration == null ? (code == null ? '-' : 'Tanpa kadaluarsa') : `Berlaku sampai: ${expiration}`}
                </p>
            </div>
        </div>
    );
};

export default Coupon;
