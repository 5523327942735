import React, { useState } from 'react';
import styles from './AccountUpdatePage.module.css'; // Adjust CSS if needed
import { updateUser } from '../helpers/userHelpers';

const AccountUpdatePage = ({ user, showEmail, onSubmit }) => {
    const [formData, setFormData] = useState({
        username: user.username.startsWith('guest') ? '' : user.username || '',
        email: user.email || '',
        password: user.password === 'unsetunsetunset' ? '' : user.password || '',
    });

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState(''); // New state for success messages

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate password length on the frontend
        if (formData.password && formData.password.length < 6) {
            setErrorMessage('Password is too short');
            return;
        }

        try {
            const response = await updateUser(formData); // Call the updated helper function

            // If there's a success message, display it
            if (response.message) {
                setSuccessMessage(response.message); // Set the success message from the response
                setErrorMessage(''); // Clear any previous error messages
            }
        } catch (error) {
            // Handle the error by displaying the error message
            setErrorMessage(error.message || 'Failed to update user. Please try again.');
            setSuccessMessage(''); // Clear success message if there's an error
        }
    };

    return (
        <div className={styles.container}>
            <h2>Update Your Account</h2>
            <form onSubmit={handleSubmit} className={styles.form}>
                <label className={styles.formLabel}>
                    Username:
                    <input
                        type="text"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        className={styles.formInput}
                    />
                </label>

                {showEmail && (
                    <label className={styles.formLabel}>
                        Email:
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className={styles.formInput}
                        />
                    </label>
                )}

                <label className={styles.formLabel}>
                    Password:
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        className={styles.formInput}
                    />
                </label>

                {/* Display success message */}
                {successMessage && <div className={styles.successMessage}>{successMessage}</div>}

                {/* Display error message */}
                {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}

                <button type="submit" className={styles.submitButton}>
                    Submit
                </button>
            </form>
        </div>
    );
};

export default AccountUpdatePage;
