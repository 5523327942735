import React, { useEffect, useState } from "react";
import styles from "./Transactions.module.css";
import { useParams } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import {
  getMyTransactions,
  confirmTransaction,
  declineTransaction,
  getTransactionsFromCafe,
} from "../helpers/transactionHelpers";
import { getTables } from "../helpers/tableHelper";
import TableCanvas from "../components/TableCanvas";

export default function Transactions({ shopId, propsShopId, sendParam, deviceType }) {
  const { shopIdentifier, tableId } = useParams();
  if (sendParam) sendParam({ shopIdentifier, tableId });

  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [myTransactions, setMyTransactions] = useState([]);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        let response;
        response = await getTransactionsFromCafe(shopId || propsShopId, 5, false);
        setTransactions(response);
        response = await getMyTransactions(shopId || propsShopId, 5);
        setMyTransactions(response);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    fetchTransactions();
  }, [shopId || propsShopId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedTables = await getTables(shopId || propsShopId);
        setTables(fetchedTables);
      } catch (error) {
        console.error("Error fetching tables:", error);
      }
    };

    fetchData();
  }, [shopId || propsShopId]);

  const calculateTotalPrice = (detailedTransactions) => {
    return detailedTransactions.reduce((total, dt) => {
      return total + dt.qty * dt.Item.price;
    }, 0);
  };

  const handleConfirm = async (transactionId) => {
    setIsPaymentLoading(true);
    try {
      const c = await confirmTransaction(transactionId);
      if (c) {
        // Update the confirmed status locally
        setTransactions((prevTransactions) =>
          prevTransactions.map((transaction) =>
            transaction.transactionId === transactionId
              ? { ...transaction, confirmed: 1 } // Set to confirmed
              : transaction
          )
        );
      }
    } catch (error) {
      console.error("Error processing payment:", error);
    } finally {
      setIsPaymentLoading(false);
    }
  };

  const handleDecline = async (transactionId) => {
    setIsPaymentLoading(true);
    try {
      const c = await declineTransaction(transactionId);
      if (c) {
        // Update the confirmed status locally
        setTransactions((prevTransactions) =>
          prevTransactions.map((transaction) =>
            transaction.transactionId === transactionId
              ? { ...transaction, confirmed: -1 } // Set to confirmed
              : transaction
          )
        );
      }
    } catch (error) {
      console.error("Error processing payment:", error);
    } finally {
      setIsPaymentLoading(false);
    }
  };

  return (
    <div className={styles.Transactions}>
      <div style={{ marginTop: "30px" }}></div>
      <h2 className={styles["Transactions-title"]}>Daftar transaksi</h2>
      <div style={{ marginTop: "30px" }}></div>
      {/* <TableCanvas tables={tables} selectedTable={selectedTable} /> */}
      <div className={styles.TransactionListContainer} style={{ padding: '0 20px 0 20px' }}>
        {transactions &&
          transactions.map((transaction) => (
            <div
              key={transaction.transactionId}
              className={styles.RoundedRectangle}
              onClick={() =>
                setSelectedTable(transaction.Table || { tableId: 0 })
              }
            >

              <div className={styles['receipt-header']}>
                {transaction.confirmed === 1 ? (
                  <ColorRing className={styles['receipt-logo']} />
                ) : transaction.confirmed ===  -1 || transaction.confirmed ===  -2 ? (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <svg
                      style={{ width: '60px', transform: 'Rotate(45deg)' }}
                      clipRule="evenodd"
                      fillRule="evenodd"
                      strokeLinejoin="round"
                      strokeMiterlimit="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m12.002 2c5.518 0 9.998 4.48 9.998 9.998 0 5.517-4.48 9.997-9.998 9.997-5.517 0-9.997-4.48-9.997-9.997 0-5.518 4.48-9.998 9.997-9.998zm0 1.5c-4.69 0-8.497 3.808-8.497 8.498s3.807 8.497 8.497 8.497 8.498-3.807 8.498-8.497-3.808-8.498-8.498-8.498zm-.747 7.75h-3.5c-.414 0-.75.336-.75.75s.336.75.75.75h3.5v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5h3.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-3.5v-3.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z"
                        fillRule="nonzero"
                      />
                    </svg>
                  </div>
                ) : transaction.confirmed === 2 ? (
                  <ColorRing className={styles['receipt-logo']} />
                ) : transaction.confirmed === 3 ? (
                  "Transaction success"
                ) : (
                  
                  <ColorRing className={styles['receipt-logo']} />
                )}


                <div className={styles['receipt-info']}>
                  <h3>{transaction.confirmed === 1 ? (
                    "Silahkan cek pembayaran"
                  ) : transaction.confirmed === -1 ? (
                    "Dibatalkan oleh kasir"
                  ) : transaction.confirmed === -2 ? (
                    "Dibatalkan oleh pelanggan"
                  ) : transaction.confirmed === 2 ? (
                    "Sedang diproses"
                  ) : transaction.confirmed === 3 ? (
                    "Transaction success"
                  ) : (
                    "Silahkan cek ketersediaan"
                  )}</h3>

                  <p>Transaction ID: {transaction.transactionId}</p>
                  <p>Payment Type: {transaction.payment_type}</p>
                </div>
              </div>
              <div className={styles['dotted-line']}>
                <div className={styles['circle-left']}>

                </div>
                <div className={styles['line']} ></div>
                <div className={styles['circle-right']} >

                </div>
              </div>

              {transaction.paymentClaimed && transaction.confirmed < 2 && (
                <div className={styles.RibbonBanner}>
                  <img src={"https://i.imgur.com/yt6osgL.png"}></img>
                  <h1>payment claimed</h1>
                </div>
              )}
              <ul>
                {transaction.DetailedTransactions.map((detail) => (
                  <li key={detail.detailedTransactionId}>
                    <span>{detail.Item.name}</span> - {detail.qty} x Rp{" "}
                    {detail.Item.price}
                  </li>
                ))}
              </ul>
              <h2 className={styles["Transactions-detail"]}>
                {transaction.serving_type === "pickup"
                  ? "Self pickup"
                  : `Serve to ${transaction.Table ? transaction.Table.tableNo : "N/A"
                  }`}
              </h2>

              {transaction.notes != "" && (
                <>
                  <div className={styles.NoteContainer}>
                    <span>Note :</span>
                    <span></span>
                  </div>

                  <div className={styles.NoteContainer}>
                    <textarea
                      className={styles.NoteInput}
                      value={transaction.notes}
                      disabled
                    />
                  </div>
                </>
              )}
              <div className={styles.TotalContainer}>
                <span>Total:</span>
                <span>
                  Rp {calculateTotalPrice(transaction.DetailedTransactions)}
                </span>
              </div>
              <div className={styles.TotalContainer}>
                <button
                  className={styles.PayButton}
                  onClick={() => handleConfirm(transaction.transactionId)}
                  disabled={
                    isPaymentLoading || // Disable if loading
                    (deviceType === "clerk" &&
                      transaction.confirmed !== 1 &&
                      transaction.confirmed !== 2 &&
                      transaction.confirmed !== 0) || // Disable for clerk when not Confirm has paid, Confirm item is ready, or Confirm availability
                    (deviceType !== "clerk" &&
                      transaction.confirmed !== 1 &&
                      transaction.paymentClaimed) || // Disable for buyer when not Claim has paid
                    transaction.confirmed === 3 || // Disable if Transaction success
                    transaction.confirmed === -1 || // Disable if Declined
                    transaction.confirmed === -2 || // Disable if Canceled
                    transaction.confirmed === 2 || // Disable if In process
                    (transaction.confirmed === 1 && transaction.paymentClaimed) // Disable if verifying payment
                  }
                >
                  {deviceType === "clerk" ? (
                    isPaymentLoading ? (
                      <ColorRing height="50" width="50" color="white" />
                    ) : transaction.confirmed === 1 ? (
                      "Confirm has paid"
                    ) : transaction.confirmed === -1 ? (
                      "Declined"
                    ) : transaction.confirmed === -2 ? (
                      "Canceled"
                    ) : transaction.confirmed === 2 ? (
                      "Confirm item is ready"
                    ) : transaction.confirmed === 3 ? (
                      "Transaction success"
                    ) : (
                      "Confirm availability"
                    )
                  ) : isPaymentLoading ? (
                    <ColorRing height="50" width="50" color="white" />
                  ) : transaction.confirmed === 1 &&
                    !transaction.paymentClaimed ? (
                    "Claim has paid"
                  ) : transaction.confirmed === 1 &&
                    transaction.paymentClaimed ? (
                    "Verifying your payment"
                  ) : transaction.confirmed === -1 ? (
                    "Declined"
                  ) : transaction.confirmed === -2 ? (
                    "Canceled"
                  ) : transaction.confirmed === 2 ? (
                    "In process"
                  ) : (
                    "Transaction success"
                  )}
                </button>
              </div>
              {transaction.confirmed == 0 && (
                <h5
                  className={styles.DeclineButton}
                  onClick={() => handleDecline(transaction.transactionId)}
                >
                  decline
                </h5>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}
