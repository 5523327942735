import React, { useState, useRef, useEffect } from "react";
import jsQR from "jsqr";
import { getImageUrl } from "../helpers/itemHelper";
import {
  getCafe,
  saveCafeDetails,
  setConfirmationStatus,
} from "../helpers/cafeHelpers";
import Switch from "react-switch"; // Import the Switch component

const SetPaymentQr = ({ shopId, 
  qrCodeUrl }) => {
  const [qrPosition, setQrPosition] = useState([50, 50]);
  const [qrSize, setQrSize] = useState(50);
  const [qrPayment, setQrPayment] = useState();
  const [qrCodeDetected, setQrCodeDetected] = useState(false);
  const [isNeedConfirmationState, setIsNeedConfirmationState] = useState(0);
  const qrPaymentInputRef = useRef(null);
  const qrCodeContainerRef = useRef(null);
  const [cafe, setCafe] = useState({});
  const [isConfig, setIsConfig] = useState(false);

  useEffect(() => {
    const fetchCafe = async () => {
      try {
        const response = await getCafe(shopId);
        setCafe(response);
        setQrPayment(getImageUrl(response.qrPayment));
        console.log(response.needsConfirmation);

        setIsNeedConfirmationState(response.needsConfirmation === true ? 1 : 0); // Set state based on fetched data
        setQrPosition([response.xposition, response.yposition]);
        setQrSize(response.scale);
      } catch (error) {
        console.error("Error fetching cafe:", error);
      }
    };
    fetchCafe();
  }, [shopId]);

  // Detect QR code when qrPayment updates
  useEffect(() => {
    if (qrPayment) {
      detectQRCodeFromContainer();
    }
  }, [qrPayment]);

  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const newqrPayment = URL.createObjectURL(file);
      setQrPayment(newqrPayment);
    }
  };

  // Detect QR code from the container
  const detectQRCodeFromContainer = () => {
    const container = qrCodeContainerRef.current;
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      canvas.width = container.offsetWidth;
      canvas.height = container.offsetHeight;
      context.drawImage(img, 0, 0, canvas.width, canvas.height);
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const qrCode = jsQR(imageData.data, canvas.width, canvas.height);
      setQrCodeDetected(!!qrCode);
      if (qrCode) {
        console.log("QR Code detected:", qrCode.data);
      }
    };
    img.src = qrPayment;
  };

  // Save cafe details
  const handleSave = async () => {
    const qrPaymentFile = qrPaymentInputRef.current.files[0];
    const details = {
      qrPosition,
      qrSize,
      qrPaymentFile,
    };

    try {
      const response = await saveCafeDetails(cafe.cafeId, details);
      console.log("Cafe details saved:", response);
    } catch (error) {
      console.error("Error saving cafe details:", error);
    }

    try {
      const response = await setConfirmationStatus(cafe.cafeId, isNeedConfirmationState === 1);
      setIsNeedConfirmationState(response.needsConfirmation ? 1 : 0); // Update state after saving
    } catch (error) {
      console.error(error);
      setIsNeedConfirmationState(cafe.needsConfirmation ? 1 : 0); // Fallback to initial value
    }
  };

  // Handle Switch toggle
  const handleChange = (checked) => {
    setIsNeedConfirmationState(checked ? 1 : 0); // Toggle state based on the switch
  };

  const handleError = () => {
    setQrPayment('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPsNr0TPq8dHT3nBwDQ6OHQQTrqzVFoeBOmuWfgyErrLbJi6f6CnnYhpNHEvkJ_2X-kyI&usqp=CAU'); // Set your fallback image here
  };

  return (
    <div style={styles.container}>
      <h3 style={styles.title}>Konfigurasi pembayaran</h3>
      <div
        id="qr-code-container"
        ref={qrCodeContainerRef}
        onClick={() => qrPaymentInputRef.current.click()}
        style={{
          ...styles.qrCodeContainer,
          backgroundImage: `url(${qrPayment})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        <input
          type="file"
          accept="image/*"
          ref={qrPaymentInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </div>
      <div style={styles.uploadMessage}>
        <p>Klik untuk ganti background</p>
      </div>
      <div style={styles.resultMessage}>
        {qrCodeDetected && qrPayment !== 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPsNr0TPq8dHT3nBwDQ6OHQQTrqzVFoeBOmuWfgyErrLbJi6f6CnnYhpNHEvkJ_2X-kyI&usqp=CAU' ? <p>QR terdeteksi</p> : <p>Tidak ada qr terdeteksi</p>}
        {qrCodeDetected && qrPayment !== 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPsNr0TPq8dHT3nBwDQ6OHQQTrqzVFoeBOmuWfgyErrLbJi6f6CnnYhpNHEvkJ_2X-kyI&usqp=CAU' ? <div
          onClick={() => qrPaymentInputRef.current.click()} style={styles.uploadButton}>Ganti</div> : <div
            onClick={() => qrPaymentInputRef.current.click()} style={styles.uploadButton}>Unggah</div>}
      </div>
      <div style={styles.switchContainer}>
        <p style={styles.description}>
          Nyalakan agar kasir memeriksa kembali ketersediaan produk sebelum pelanggan membayar.
        </p>
        <Switch
          onChange={handleChange}
          checked={isNeedConfirmationState === 1} // Convert to boolean
          offColor="#888"
          onColor="#4CAF50"
          uncheckedIcon={false}
          checkedIcon={false}
          height={25}
          width={50}
        />
      </div>

      <div style={styles.buttonContainer}>
        <button onClick={handleSave} style={styles.saveButton}>
          Simpan
        </button>
      </div>
    </div>
  );
};

// Styles
const styles = {
  container: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    textAlign: "center", // Center text and children
  },
  title: {
    marginBottom: "20px",
    fontWeight: "bold",
  },
  qrCodeContainer: {
    backgroundColor: '#999999',
    borderRadius: '20px',
    position: "relative",
    width: "100%",
    height: "200px",
    backgroundSize: "contain",
    overflow: "hidden",
    margin: "0 auto", // Center the QR code container
  },
  uploadMessage: {
    fontWeight: 600,
    textAlign: "left",
  },
  uploadButton: {
    paddingRight: '10px',
    backgroundColor: 'green',
    borderRadius: '30px',
    color: 'white',
    fontWeight: 700,
    height: '36px',
    lineHeight: '36px',
    paddingLeft: '10px',
    paddingHeight: '10px',
  },
  resultMessage: {
    marginTop: "-24px",
    textAlign: "left",
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonContainer: {
    marginTop: "20px",
    textAlign: "left",
  },
  saveButton: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#28a745",
    color: "#fff",
    border: "none",
    borderRadius: "30px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  switchContainer: {
    textAlign: "left",
  },
  description: {
    margin: "10px 0",
    fontSize: "14px",
    color: "#666",
  },
  sliderContainer: {
    marginBottom: "20px",
  },
  label: {
    display: "block",
    marginBottom: "10px",
  },
  sliderWrapper: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    flex: "1",
    margin: "0 10px",
  },
};

export default SetPaymentQr;
