// src/CafePage.js

import React, { useState, useEffect } from "react";
import {
  useParams,
  useSearchParams,
  useNavigate,
  useLocation,
} from "react-router-dom";

import "../App.css";

import { getImageUrl, createItem, updateItem, moveItemType } from "../helpers/itemHelper.js";
import SearchInput from "../components/SearchInput";
import ItemTypeLister from "../components/ItemTypeLister";
import { MusicPlayer } from "../components/MusicPlayer";
import ItemLister from "../components/ItemLister";
import Header from "../components/Header";

import { ThreeDots } from "react-loader-spinner";

import { getLocalStorage, updateLocalStorage, removeLocalStorage } from "../helpers/localStorageHelpers";
import { unsubscribeUser } from "../helpers/subscribeHelpers.js";
import WelcomePage from "./WelcomePage.js";

function CafePage({
  shopId,
  table,
  sendParam,
  welcomePageConfig,
  shopName,
  shopOwnerId,
  setShopItems,
  shopItems,
  shopClerks,
  socket,
  user,
  guestSides,
  guestSideOfClerk,
  removeConnectedGuestSides,
  setModal,
  loading,
  queue
}) {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { shopIdentifier, tableCode } = useParams();
  sendParam({ shopIdentifier, tableCode });
  
  const navigate = useNavigate();

  const [screenMessage, setScreenMessage] = useState("");

  const [isSpotifyNeedLogin, setNeedSpotifyLogin] = useState(true);

  const [isEditMode, setIsEditMode] = useState(false);
  const [filterId, setFilterId] = useState(0);
  const [isStarted, setIsStarted] = useState(false);

  const [config, setConfig] = useState({});

  const [beingEditedType, setBeingEditedType] = useState(0);

  const checkWelcomePageConfig = () => {
    const parsedConfig = JSON.parse(welcomePageConfig);
    if (parsedConfig.isWelcomePageActive == "true") {
      const clicked = sessionStorage.getItem("getStartedClicked");
      if (!clicked) {
        sessionStorage.setItem("getStartedClicked", true);
        document.body.style.overflow = "hidden";
        setIsStarted(true);
      }
    }
  };

  useEffect(() => {
    if (welcomePageConfig) {
      const parsedConfig = JSON.parse(welcomePageConfig);
      setConfig({
        image: getImageUrl(parsedConfig.image) || "",
        welcomingText: parsedConfig.welcomingText || "Enjoy your coffee!",
        backgroundColor: parsedConfig.backgroundColor || "#ffffff",
        textColor: parsedConfig.textColor || "#000000",
        isActive: parsedConfig.isWelcomePageActive === "true",
      });
    }
    checkWelcomePageConfig();
  }, [welcomePageConfig]);


  useEffect(() => {
    function fetchData() {
      console.log(user.userId == shopOwnerId)
      setModal("create_item");

    }
    console.log(getLocalStorage('auth'))
    if (getLocalStorage("auth") != null) {
      const executeFetch = async () => {
        while (user.length == 0) {
          await new Promise((resolve) => setTimeout(resolve, 100)); // Wait until the user is not null
        }
        console.log(user)
        console.log('open')
        if (user.length != 0 && user.userId == shopOwnerId && shopItems.length == 0) fetchData();
      };
      executeFetch();
    }
  }, [user, shopItems]);

  useEffect(() => {
    if (token) {
      updateLocalStorage("auth", token);
    }
  }, [token]);

  const handleLogout = () => {
    removeLocalStorage("auth");
    unsubscribeUser();
    navigate(0);
  };

  useEffect(() => {
    async function fetchData() {
      socket.on("joined-room", (response) => {
        const { isSpotifyNeedLogin } = response;
        setNeedSpotifyLogin(isSpotifyNeedLogin);
      });
    }

    if (socket) fetchData();
  }, [socket]);

  const handleGetStarted = () => {
    setIsStarted(false);

    document.body.style.overflow = "auto";
  };

  const moveItemTypeHandler = async (itemTypeId, direction, index) => {
    const previousItems = [...shopItems];
    
    // Update local state immediately
    const newItems = [...shopItems];
  
    let targetIndex;
    if (direction === 'up' && index > 0) {
      targetIndex = index - 1;
    } else if (direction === 'down' && index < newItems.length - 1) {
      targetIndex = index + 1;
    }
    console.log(index);
    console.log(targetIndex);
    if (targetIndex !== undefined) {
      // Swap items
      [newItems[index], newItems[targetIndex]] = [newItems[targetIndex], newItems[index]];
      newItems[index].order = targetIndex;
      newItems[targetIndex].order = index;
    
      setShopItems(newItems);
  
      // Call the API to move the item type
      try {
        await moveItemType(itemTypeId, previousItems[targetIndex].itemTypeId, index, targetIndex);
      } catch (error) {
        console.error('Error moving item type:', error);
        // Revert the changes if the backend fails
        setShopItems(previousItems);
      }
    }
  };
  
  if (loading)
    return (
      <div className="Loader">
        <div className="LoaderChild">
          <ThreeDots />
          <h1>{screenMessage}</h1>
        </div>
      </div>
    );
  else
    return (
      <>
        {welcomePageConfig && isStarted ? (
          <WelcomePage
            image={config.image}
            welcomingText={config.welcomingText}
            backgroundColor={config.backgroundColor}
            textColor={config.textColor}
            isActive={config.isActive}
            onGetStarted={handleGetStarted}
            isFullscreen={true}
          />
        ) : (
          welcomePageConfig != null && (
            <div className="Cafe">
              <body className="App-header">
                <Header
                  HeaderText={"Menu"}
                  showProfile={true}
                  setModal={setModal}
                  isLogout={handleLogout}
                  shopId={shopId}
                  shopName={shopName}
                  shopImage={config.image}
                  shopOwnerId={shopOwnerId}
                  shopClerks={shopClerks}
                  tableCode={table.tableCode}
                  user={user}
                  guestSides={guestSides}
                  guestSideOfClerk={guestSideOfClerk}
                  removeConnectedGuestSides={removeConnectedGuestSides}
                  setIsEditMode={(e) => setIsEditMode(e)}
                  isEditMode={isEditMode}
                />
                <MusicPlayer
                  socket={socket}
                  shopId={shopId}
                  user={user}
                  shopOwnerId={shopOwnerId}
                  isSpotifyNeedLogin={isSpotifyNeedLogin}
                  queue={queue}
                />
                <ItemTypeLister
                  user={user}
                  shopOwnerId={shopOwnerId}
                  shopId={shopId}
                  itemTypes={shopItems}
                  setShopItems={setShopItems}
                  isEditMode={isEditMode}
                  onFilterChange={(e) => setFilterId(e)}
                  filterId={filterId}
                  beingEditedType={beingEditedType}
                  setBeingEditedType={setBeingEditedType}
                />

                {/* <div style={{ marginTop: "15px" }}></div> */}
                {shopItems
                  .filter(
                    (itemType) =>
                      filterId == 0 || itemType.itemTypeId === filterId
                  )
                  .map((itemType, index) => (
                    <ItemLister
                      index={index}
                      indexTotal={shopItems.length}
                      shopId={shopId}
                      shopOwnerId={shopOwnerId}
                      user={user}
                      key={itemType.itemTypeId}
                      itemTypeId={itemType.itemTypeId}
                      typeName={itemType.name}
                      typeImage={itemType.image}
                      setShopItems={setShopItems}
                      itemList={itemType.itemList}
                      typeVisibility={itemType.visibility}
                      moveItemTypeUp={(e)=>moveItemTypeHandler(e,'up', index)}
                      moveItemTypeDown={(e)=>moveItemTypeHandler(e, 'down', index)}
                      isEditMode={isEditMode}
                      beingEditedType={beingEditedType}
                      setBeingEditedType={setBeingEditedType}
                      raw={isEditMode || filterId == 0 ? false : true}
                      handleCreateItem={(
                        itemTypeID,
                        name,
                        price,
                        selectedImage
                      ) =>
                        createItem(
                          shopId,
                          name,
                          price,
                          selectedImage,
                          itemTypeID
                        )
                      }
                      handleUpdateItem={(itemId, name, price, selectedImage) =>
                        updateItem(itemId, name, price, selectedImage)
                      }
                    />
                  ))}
              </body>
            </div>
          )
        )}
      </>
    );
}

export default CafePage;
